<template>
  <div class="content block-el">
    <PageHeader :title="$t('sms.short-code.edit')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-tabs">
            <div class="card-header p-0 no-border">
              <ul class="nav nav-tabs nav-fill primary-tabs">
                <li class="nav-item" role="presentation">
                  <a
                    href="javascript:void(0)"
                    v-tab="'configure'"
                    class="nav-link active"
                    data-toggle="tab"
                    aria-expanded="true"
                    >{{$t('generic-str.config')}}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    href="javascript:void(0)"
                    v-tab="'history'"
                    class="nav-link"
                    data-toggle="tab"
                    aria-expanded="true"
                    @click="fetchMessages"
                    >{{$t('generic-str.menu.history')}}</a
                  >
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <div class="tab-pane active" id="configure">
                <form @submit.prevent="update">
                  <div class="card-body">
                    <h5 class="card-title">{{$t('properties.title')}}</h5>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="name">{{$t('sms.short-code.lbl-nickname')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('sms.short-code.lbl-nickname')"
                          v-model="form.data.alias"
                        />
                      </div>
                      <div class="form-group col-2">
                        <label for="name">{{$tc('generic-str.lbl-number', 1)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.name"
                          disabled
                        />
                      </div>
                      <div class="form-group col-4">
                        <label for="name">ID</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.data.id"
                          disabled
                        />
                      </div>
                      <div class="form-group col-2">
                        <label for="name">{{$t('generic-str.locality')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          value="Brasil"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer bg-light">
                    <button type="submit" class="btn btn-primary">
                      {{$t('generic-str.save')}}
                    </button>
                  </div>
                </form>
              </div>
              <div class="tab-pane" id="history">
                <div class="responsive-table">
                  <table v-if="fetched" class="table">
                    <thead class="bg-light">
                      <tr>
                        <th scope="col">{{$t('generic-str.date')}}</th>
                        <th scope="col">{{$t('generic-str.type')}}</th>
                        <th scope="col">{{$t('generic-str.lbl-sender')}}</th>
                        <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
                        <th scope="col">{{$tc('generic-str.message', 1)}}</th>
                        <th scope="col">{{$tc('generic-str.part', 2)}}</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="message in messages"
                        :key="message.id"
                        :to="`history/${message.id}`"
                      >
                        <td data-label="Data">
                          <div>
                            <router-link :to="`history/${message.id}`">
                              {{ message.created_at }}
                            </router-link>
                          </div>
                        </td>
                        <td data-label="Tipo">
                          <template v-if="message.direction == 'outbound'">
                            {{$t('generic-str.send-2')}}
                          </template>
                          <template v-else-if="message.direction == 'inbound'">
                            {{$tc('generic-str.status.lbl-response', 1)}}
                          </template>
                        </td>
                        <td data-label="Remetente">{{ message.from }}</td>
                        <td data-label="Destinatário">{{ message.to }}</td>
                        <td
                          data-label="Mensagem"
                          class="max-200"
                          v-tooltip.top="`${message.body}`"
                        >
                          {{ message.body }}
                        </td>
                        <td data-label="Partes">{{ message.segments }}</td>
                        <td data-label="Status">
                          <span
                            v-if="message.status == 'accepted'"
                            class="badge badge-light"
                            >{{$tc('generic-str.status.lbl-pending', 1)}}</span
                          >
                          <span
                            v-else-if="message.status == 'queued'"
                            class="badge badge-secondary"
                            >{{$t('generic-str.status.lbl-queue')}}</span
                          >
                          <span
                            v-else-if="message.status == 'sending'"
                            class="badge badge-accent"
                            >{{$t('generic-str.status.lbl-sending')}}</span
                          >
                          <span
                            v-else-if="message.status == 'sent'"
                            class="badge badge-info"
                            >{{$t('generic-str.status.lbl-sent')}}</span
                          >
                          <span
                            v-else-if="message.status == 'failed'"
                            class="badge badge-danger"
                            v-tooltip.top="
                              `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                            "
                            >{{$t('generic-str.status.lbl-failure')}}</span
                          >
                          <span
                            v-else-if="message.status == 'delivered'"
                            class="badge badge-success"
                            >{{$t('generic-str.status.lbl-delivered')}}</span
                          >
                          <span
                            v-else-if="message.status == 'undelivered'"
                            class="badge badge-dark"
                            v-tooltip.top="
                              `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                            "
                            >{{$t('generic-str.status.lbl-unavailable')}}</span
                          >
                          <span
                            v-else-if="message.status == 'received'"
                            class="badge badge-success"
                            >{{$tc('generic-str.status.lbl-response', 1)}}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-else
                    class="static qt-block-ui"
                    style="padding: 120px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SmsService from '@/services/sms.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'ShortCodes',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fetched: false,
      updating: false,
      shortCodes: [],
      messages: [],
      form: {},
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      SmsService.getAccountShortCode(this.$route.params.id)
        .then(
          (shortCode) => {
            this.form = shortCode;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetchMessages() {
      this.fetched = false;
      SmsService.getMessages({
        page: 1,
        from: this.form.name,
      })
        .then(
          (response) => {
            this.messages = response.data;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    update() {
      this.updating = true;
      SmsService.updateAccountShortCode(this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Short Code Atualizado',
              type: 'success',
            });
            this.$router.push('./');
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
</style>
